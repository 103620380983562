import React from 'react';
import service1 from '../../assets/images/services/service1.png';
import service2 from '../../assets/images/services/service2.png';
import service3 from '../../assets/images/services/service3.png';

const OurServices = () => {
  return (
    <React.Fragment>
      {/* Service Left Image Style */}
      <div className="about-area pb-100" id="services">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-img">
                <img src={service2} alt="service" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <div className="content">
                  <h2>Design & Development</h2>

                  <p>
                    From translating your ideas into reality to revamping
                    existing products, our skilled team of engineers and
                    designers do it all
                  </p>
                  <ul className="about-list mb-0">
                    <li>
                      <i className="flaticon-tick"></i>
                      Protyping
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      UI/UX Design
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Web Development
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      App Development
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      E-Commerce Store
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>SaaS Solutions
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Left Image Style */}

      {/* Service Right Image Style */}
      <div className="our-mission-area pb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="our-mission-content">
                <div className="content">
                  <h2>AI / Machine Learning</h2>
                  <p>
                    Unlock the potential of your data with our expert services
                    in AI and machine learning, tailored to propel your business
                    forward
                  </p>

                  <ul className="our-mission-list mb-0">
                    <li>
                      <i className="flaticon-tick"></i>
                      AI Agents & Chatbots
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      NLP and LLMs
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      ML Algorithms
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Predictive Modeling
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Data Engineering
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Data Analysis & Visualization
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="our-mission-image">
                <img src={service1} alt="service" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Right Image Style */}

      {/* Service Left Image Style */}
      <div className="about-area pb-100" id="services">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-img">
                <img src={service3} alt="service" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <div className="content">
                  <h2>Product Marketing</h2>

                  <p>
                    Once we have your product ready and deployed, we help you
                    grow through various marketing strategies we have to offer
                  </p>
                  <ul className="about-list mb-0">
                    <li>
                      <i className="flaticon-tick"></i>
                      Affiliate Marketing
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Email Marketing
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      SEO / SEM
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>Growth Hacking
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Content Creation
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>Advertisments
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Left Image Style */}
    </React.Fragment>
  );
};

export default OurServices;
