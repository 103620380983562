import React from 'react';
import client1 from '../../assets/images/testimonials/client1.jpg';
import client2 from '../../assets/images/testimonials/client2.jpg';
import client3 from '../../assets/images/testimonials/client3.jpg';
import testimonials from '../../assets/data/testimonials.json';
import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel3'));

const options = {
  loop: true,
  nav: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  margin: 30,
  navText: [
    "<i class='flaticon-left-1'></i>",
    "<i class='flaticon-right-1'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 1,
    },
    992: {
      items: 1,
    },
  },
};

const Testimonials = () => {
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    setDisplay(true);
  }, []);

  return (
    <div className="testimonials-area pt-100 bg-f1f8fb" id="testimonials">
      <div className="divider inverted"></div>
      <div className="container">
        <div className="section-title">
          <h2>Our Valued Clients</h2>
          <p>Discover the companies we've partnered with for tech success</p>
        </div>

        {display ? (
          <OwlCarousel
            className="testimonials-slides owl-carousel owl-theme"
            {...options}
          >
            {/* {testimonials.map((t) => (
              <div className="single-testimonials-item">
                <p>{t.message}</p>

                <div className="client-info">
                  <div className="d-flex justify-content-center align-items-center">
                    {<img src={client1} alt="testimonial" />}
                    <div className="title">
                      <h3>{t.name}</h3>
                      <span>{`${t.designation} at ${t.company}`}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))} */}
          </OwlCarousel>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Testimonials;
